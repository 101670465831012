body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #02bbd7;
}

header {
  display: flex;
  justify-content: center;
}

h2 {
  color: white;
  text-decoration: underline;
  padding: 20px 0 0 0;
}

p {
  color: white;
  font-size: 1.2em;
  margin: 20px;
}

#opener {
  color: white;
  font-size: 2em;
}

#footer {
  height: 60px;
  background: #027abb;
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer ul li {
  display: inline;
  padding: 0 40px;
}

#footer a {
  color: #111;
}

.App {
  text-align: center;
  height: 100vh;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #222;
}

.App-logo {
  height: 350px;
}

.photo-box {
  padding: 20px;
  border-width: 5px;
  border-color: #02bbd7;
  border-style: dashed;
}

.dropped-photo {
  height: 400px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
}

.App-title {
  padding: 40px;
  font-size: 1.5em;
  /* animation: App-logo-spin infinite 20s linear; */
}

#spinContainer {
  position: absolute;
  left: 50%;
  top: 2.5em;
}

#nicStatus {
  position: relative;
  left: -50%;
}

.processing {
  -webkit-animation: process-spin infinite 5s linear;
          animation: process-spin infinite 5s linear;
  width: 250px;
  position: relative;
  left: -50%;
}

.App-intro {
  font-size: large;
}

#detection {
  height: 200px;
}

#gapHolder {
  height: 203px;
}

.appStore {
  width: 100%;
}

#root {
  flex: 1 1;
}

@-webkit-keyframes process-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes process-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.linkStuff {
  padding-top: 50px;
  color: #02bbd7;
}

